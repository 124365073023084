/** How long on the kiosk before we show the `StillThereModal` to the user due to user inactivity */
export const KIOSK_SESSION_TIMEOUT = 120000 as const;

/** Once the `StillThereModal` has been open for this long, close it and start a new session */
export const KIOSK_INACTIVITY_TIMEOUT = 60000 as const;

/** the timeout interval before prompting the user to re-start the payment process */
export const KIOSK_PAYMENT_TIMEOUT = 100000 as const;

/** The duration the printing success screen appears */
export const KIOSK_SUCCESS_SCREEN_TIMEOUT = 20000 as const;

/** The "cold start" time of sending the first card to the printer */
export const KIOSK_PRINT_BASE_DELAY = 30000 as const;

/** The approximate time elapsed between prints */
export const KIOSK_PRINT_PER_CARD_DELAY = 5000 as const;

/** The duration (without user input) before the fulfillment flow will simply push to the success screen */
export const KIOSK_EMAIL_COLLECTION_TIMEOUT = 25000 as const;
